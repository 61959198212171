import React, { useState, useEffect } from 'react';
import Logo from '../../Markup/assets/img/WpdLogoWithText.svg';
import CartEmptyIllustration from '../../Markup/assets/img/Cart-empty.svg';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import EA from '../../Markup/assets/img/EA-Logo.png';
import BD from '../../Markup/assets/img/Betterdocs-icon.png';
import NX from '../../Markup/assets/img/NotificationX.svg';
import EP from '../../Markup/assets/img/embedpress-icon.svg';
import BL from '../../Markup/assets/img/betterlinks-icon.png';
import SP from '../../Markup/assets/img/wp-scheduled-posts-logo.png';
import RX from '../../Markup/assets/img/reviewx-pp.png';
import Agency from '../../Markup/assets/img/wpdev-all-access-bundle.png';
import EB from '../../Markup/assets/img/essential-blocks-icon.png';
import BP from '../../Markup/assets/img/better-payment.png';
import Footer from '../Common/Footer';

const staticProducts = [
  {
    id: 1,
    name: 'Essential Addons',
    icon: EA,
    priceOptions: [
      {
        value: '1 Website',
        label: '1 Website',
        price: '$49.97',
        description: [
          'Activation on 1 site',
          '1 Year Support',
          '1 Year Update',
        ],
        default: true,
        priceId: 1,
        downloadId: 4372,
      },
      {
        value: 'Unlimited',
        label: 'Unlimited',
        price: '$99.97',
        description: [
          'Unlimited Activation',
          '1 Year Support',
          '1 Year Update',
        ],
        priceId: 3,
        downloadId: 4372,
      },
      {
        value: 'Lifetime Unlimited',
        label: 'Lifetime Unlimited',
        price: '$249.97',
        description: [
          'Unlimited Activation',
          'Lifetime Support',
          'Lifetime Updates',
          'No Renewal Required',
        ],
        priceId: 4,
        downloadId: 4372,
      },
    ],
  },
  {
    id: 2,
    name: 'BetterDocs',
    icon: BD,
    priceOptions: [
      {
        value: 'Starter',
        label: 'Starter',
        price: '$69',
        description: [
          'Activation on 1 Site',
          '1 Year Support',
          '1 Year Update',
        ],
        default: true,
        priceId: 1,
        downloadId: 342422,
      },
      {
        value: 'Innovator',
        label: 'Innovator',
        price: '$149',
        description: [
          'Unlimited Activation',
          '1 Year Support',
          '1 Year Update',
        ],
        priceId: 2,
        downloadId: 342422,
      },
      {
        value: 'Visionary',
        label: 'Visionary',
        price: '$299',
        description: [
          'Unlimited Activation',
          'Lifetime Support',
          'Lifetime Updates',
          'No Renewal Required',
        ],
        priceId: 3,
        downloadId: 342422,
      },
    ],
  },
  {
    id: 3,
    name: 'NotificationX',
    icon: NX,
    priceOptions: [
      {
        value: 'Individual',
        label: 'Individual',
        price: '$49',
        description: [
          'Activation on 1 site',
          '1 Year Support',
          '1 Year Update',
        ],
        default: true,
        priceId: 1,
        downloadId: 99658,
      },
      {
        value: 'Business',
        label: 'Business',
        price: '$199',
        description: [
          'Unlimited Activation',
          '1 Year Support',
          '1 Year Update',
        ],
        priceId: 3,
        downloadId: 99658,
      },
      {
        value: 'Lifetime Unlimited',
        label: 'Lifetime Unlimited',
        price: '$399',
        description: [
          'Unlimited Activation',
          'Lifetime Support',
          'Lifetime Updates',
          'No Renewal Required',
        ],
        priceId: 4,
        downloadId: 99658,
      },
    ],
  },
  {
    id: 4,
    name: 'EmbedPress',
    icon: EP,
    priceOptions: [
      {
        value: 'Individual',
        label: 'Individual',
        price: '$69',
        description: [
          'Activation on 1 site',
          '1 Year Support',
          '1 Year Update',
          'Standard support',
        ],
        default: true,
        priceId: 1,
        downloadId: 414494,
      },
      {
        value: 'Business',
        label: 'Business',
        price: '$199',
        description: [
          'Unlimited Activation',
          '1 Year Support',
          '1 Year Update',
          'Priority support',
        ],
        priceId: 2,
        downloadId: 414494,
      },
      {
        value: 'Lifetime Unlimited',
        label: 'Lifetime Unlimited',
        price: '$399',
        description: [
          'Unlimited Activation',
          'Lifetime Priority Support',
          'Lifetime Updates',
          'No Renewal Required',
        ],
        priceId: 3,
        downloadId: 414494,
      },
    ],
  },
  {
    id: 5,
    name: 'BetterLinks',
    icon: BL,
    priceOptions: [
      {
        value: 'Individual',
        label: 'Individual',
        price: '$99',
        description: [
          'Activation on 1 site',
          '1 Year Support',
          '1 Year Update',
        ],
        default: true,
        priceId: 1,
        downloadId: 764539,
      },
      {
        value: 'Business',
        label: 'Business',
        price: '$249',
        description: [
          'Unlimited Activation',
          '1 Year Support',
          '1 Year Update',
        ],
        priceId: 2,
        downloadId: 764539,
      },
      {
        value: 'Agency (Lifetime)',
        label: 'Agency (Lifetime)',
        price: '$399',
        description: [
          'Unlimited Activation',
          'Lifetime Support',
          'Lifetime Updates',
          'No Renewal Required',
        ],
        priceId: 3,
        downloadId: 764539,
      },
    ],
  },
  {
    id: 6,
    name: 'SchedulePress',
    icon: SP,
    priceOptions: [
      {
        value: 'Individual',
        label: 'Individual',
        price: '$49',
        description: [
          'Activation on 1 site',
          '1 Year Support',
          '1 Year Update',
        ],
        default: true,
        priceId: 1,
        downloadId: 78593,
      },
      {
        value: 'Business',
        label: 'Business',
        price: '$149',
        description: [
          'Unlimited Activation',
          '1 Year Support',
          '1 Year Update',
        ],
        priceId: 3,
        downloadId: 78593,
      },
      {
        value: 'Lifetime Unlimited',
        label: 'Lifetime Unlimited',
        price: '$399',
        description: [
          'Unlimited Activation',
          'Lifetime Support',
          'Lifetime Updates',
          'No Renewal Required',
        ],
        priceId: 4,
        downloadId: 78593,
      },
    ],
  },
  {
    id: 7,
    name: 'Essential Blocks',
    icon: EB,
    priceOptions: [
      {
        value: 'Starter',
        label: 'Starter',
        price: '$49',
        description: [
          'Activation on 1 site',
          '1 Year Support',
          '1 Year Update',
        ],
        default: true,
        priceId: 1,
        downloadId: 1677666,
      },
      {
        value: 'Innovator',
        label: 'Innovator',
        price: '$149',
        description: [
          'Activation on 5 Sites',
          '1 Year Support',
          '1 Year Update',
        ],
        priceId: 2,
        downloadId: 1677666,
      },
      {
        value: 'Visionary',
        label: 'Visionary',
        price: '$349',
        description: [
          'Activation on 100 Sites',
          '1 Year Support',
          '1 Year Update',
        ],
        priceId: 3,
        downloadId: 1677666,
      },
    ],
  },
  {
    id: 8,
    name: 'Better Payment',
    icon: BP,
    priceOptions: [
      {
        value: 'Single Sites',
        label: 'Single Sites',
        price: '$59',
        description: [
          'Activation on 1 site',
          '1 Year Support',
          '1 Year Update',
        ],
        default: true,
        priceId: 1,
        downloadId: 1738966,
        coupon: 'Candy25',
      },
      {
        value: 'Unlimited Sites',
        label: 'Unlimited Sites',
        price: '$199',
        description: [
          'Unlimited Activation',
          '1 Year Support',
          '1 Year Update',
        ],
        priceId: 2,
        downloadId: 1738966,
        coupon: 'Candy25',
      },
      {
        value: 'Lifetime',
        label: 'Lifetime',
        price: '$499',
        description: [
          'Unlimited Activation',
          'Lifetime Support',
          'Lifetime Updates',
          'No Renewal Required',
        ],
        priceId: 3,
        downloadId: 1738966,
        coupon: 'Candy25',
      },
    ],
  },
  {
    id: 9,
    name: 'Agency Bundle',
    icon: Agency,
    priceOptions: [
      {
        value: 'Agency Bundle Unlimited',
        label: 'Agency Bundle Unlimited',
        price: '$349',
        description: ['Unlimited Sites', '1 Year Support', '1 Year Update'],
        default: true,
        priceId: 1,
        downloadId: 474227,
      },
      {
        value: 'Agency Bundle Lifetime Unlimited',
        label: 'Agency Bundle Lifetime Unlimited',
        price: '$799',
        description: [
          'Unlimited Activation',
          'Lifetime Support',
          'Lifetime Updates',
          'No Renewal Required',
        ],
        priceId: 2,
        downloadId: 474227,
        coupon: '',
      },
    ],
  },
];
export const EmptyCart = ({ addToCart, loading, cartData }) => {
  const [allSelected, setAllSelected] = useState([]);

  useEffect(() => {
    if (localStorage.getItem('old_cart')) {
      localStorage.removeItem('old_cart');
    }
  }, []);

  const setProductSelectedPrice = (selectedOption, product) => {
    const all = allSelected.filter((val) => val.productId !== product.id);
    setAllSelected([...all, { productId: product.id, ...selectedOption }]);
  };

  const getSelectedOption = (product) => {
    if (allSelected && allSelected.length) {
      const hasSelected = allSelected
        .filter((option) => option.productId === product.id)
        .shift();
      if (hasSelected) {
        const getSelected = product.priceOptions
          .filter(
            (option) =>
              option.downloadId === hasSelected.downloadId &&
              option.priceId === hasSelected.priceId
          )
          .shift();
        if (getSelected) {
          return getSelected;
        }
      }
    }
    return product.priceOptions.filter((option) => !!option.default);
  };
  const Description = ({ product }) => {
    let option = undefined;
    if (allSelected && allSelected.length) {
      const hasSelected = allSelected.filter(
        (val) => val.productId === product.id
      );
      if (hasSelected.length) {
        option = hasSelected.shift();
      }
    }
    if (!option) {
      option = product.priceOptions.filter((val) => !!val.default).shift();
    }
    return (
      <>
        <p className="WpdProductPrice">
          <b>Price: </b>
          {option.price}
        </p>
        {typeof option.description === 'object' ? (
          <ul className="WpdProductPriceInfoDescription">
            {option.description.map((data) => {
              return <li key={Math.random()}>{data}</li>;
            })}
          </ul>
        ) : (
          <p className="WpdProductPriceInfoDescription">
            <b>Details: </b>
            {option.description}
          </p>
        )}
      </>
    );
  };
  const handleAddToCart = (e, product) => {
    e.preventDefault();
    let selectedPrice = undefined;
    if (allSelected.length) {
      selectedPrice = allSelected
        .filter((val) => val.productId === product.id)
        .shift();
    }
    if (!selectedPrice) {
      selectedPrice = product.priceOptions
        .filter((val) => !!val.default)
        .shift();
    }
    if (selectedPrice) {
      addToCart({
        download_id: selectedPrice.downloadId,
        price_id: selectedPrice.priceId,
        coupon: selectedPrice.coupon ?? '',
      });
    }
  };
  return (
    <div className="WpdPageBody WpdEmptyCartBody WpdDarkView">
      <header className="WpdEmptyCartHeader">
        <div className="WpdEmptyCartHeaderWrapper">
          <Link to={'/auth/sign-in'} className="WpdCompanyLogo mr-2">
            <img src={Logo} alt="WPDeveloper" />
          </Link>
          <ul className="nav d-flex justify-content-end ml-auto">
            <li className="nav-item">
              <a
                href={`${process.env.REACT_APP_SITE_URL}/about`}
                className="nav-link text-white py-1 px-2"
              >
                About
              </a>
            </li>
            <li className="nav-item ml-2">
              <a
                href={`${process.env.REACT_APP_SITE_URL}/blog`}
                className="nav-link text-white py-1 px-2"
              >
                Blog
              </a>
            </li>
            <li className="nav-item ml-2">
              <a
                href={`${process.env.REACT_APP_SITE_URL}/docs`}
                className="nav-link text-white py-1 px-2"
              >
                Docs
              </a>
            </li>
          </ul>
        </div>
      </header>
      <div className="WpdEmptyCartWrapper">
        <div className="WpdEmptyCartMessageWrapper">
          <div className="WpdEmptyCartMessage">
            <h2 className="WpdEmptyCartMessageTitle">Your Cart is Empty</h2>
            <p className="WpdEmptyCartMessageText">
              Looks like you haven't added any Products to your Cart yet, you
              can add products from below.
            </p>
          </div>
          <img src={CartEmptyIllustration} alt="" className="w-100 img-fluid" />
        </div>
        <div className="WpdProductPromoSection">
          {staticProducts.map((item, index) => {
            return (
              <div
                className={`WpdProductPromo ${
                  item.id === 9 ? 'WpdProductPromoAgency' : ''
                }`}
                key={Math.random()}
              >
                <div className="WpdProductPromoThumbnailWrapper">
                  <div className="WpdProductPromoThumbnail">
                    <img src={item.icon} alt="NotificationX" />
                  </div>
                </div>
                <div className="WpdProductPromoDetails">
                  <h2 className="WpdProductPromoTitle">{item.name}</h2>
                  <div className="WpdProductPriceInfoWrapper">
                    <Select
                      options={item.priceOptions}
                      value={getSelectedOption(item)}
                      onChange={(selected) =>
                        setProductSelectedPrice(selected, item)
                      }
                      className="WpdCartSelect"
                      classNamePrefix="WpdCartSelect"
                      placeholder={'Select from below'}
                    />
                    <Description product={item} />
                  </div>
                  <button
                    className="WpdButton WpdInfoButton WpdFilled"
                    onClick={(e) => handleAddToCart(e, item)}
                  >
                    <span className="WpdButtonInner">
                      <span className="WpdText">Add to Cart</span>
                    </span>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EmptyCart;
